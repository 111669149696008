/* eslint-disable react/prop-types */

import React from 'react';
import { Link } from 'gatsby';
import { Stack, Header } from '../../../../../ui-kit';
import './normal-ambassador.scss';

const COMPONENT_NAME = 'normal-ambassador';

export default function NormalAmbassador({ slug, image, name, tagLine }) {
  const imageAlt = `Portrait image of ${name}`;
  return (
    <Link to={slug} className={`${COMPONENT_NAME}__container`}>
      <Stack vertical spacing="none">
        <img
          src={image}
          alt={imageAlt}
          className={`${COMPONENT_NAME}__image`}
        />
        <Header>
          <span className={`${COMPONENT_NAME}__name`}>{name}</span>
        </Header>
        <span className={`${COMPONENT_NAME}__country`}>{tagLine}</span>
      </Stack>
    </Link>
  );
}
