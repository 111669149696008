/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';
import {
  DisplayText,
  Header,
  HeroImage,
  LayoutUI,
  Stack,
  TextStyle,
  TwoUp
} from '../../../ui-kit';
import FeaturedAmbassador from './components/FeaturedAmbassador';
import NormalAmbassador from './components/NormalAmbassador';

import './ambassadors.scss';

const PAGE_NAME = 'ambassador';

const AmbassadorPage = ({ data }) => {
  // page data
  const { hero } = data.contentfulPageAmbassadorHub;

  // ambassador profiles
  const { edges } = data.allContentfulPageIndividualAmbassador;

  const featuredAmbassador = [];
  const normalAmbassador = [];
  edges.map(({ node }) => {
    if (node.featuredAmbassador) {
      featuredAmbassador.push(node);
      return featuredAmbassador;
    }

    normalAmbassador.push(node);
    return normalAmbassador;
  });

  return (
    <Layout>
      <Navbar color="white" />
      <MobileNavbar color="white" />
      <div>
        {hero.images && hero.images[0].file && (
          <HeroImage image={hero.images[0].file.url} height="large" noMargin>
            <LayoutUI narrow>
              <Stack alignment="center" vertical>
                <div className={`${PAGE_NAME}__title`}>
                  <DisplayText>
                    <span className={`${PAGE_NAME}__title-text`}>
                      {hero.title}
                    </span>
                  </DisplayText>
                </div>
                <TextStyle color="white" center>
                  <p>{hero.subtitle.subtitle}</p>
                </TextStyle>
              </Stack>
            </LayoutUI>
          </HeroImage>
        )}

        {/* <ColoredSection color="gray-lighter">
            <LayoutUI narrow>
              <Stack vertical alignment="center" spacing="loose">
                <Header>How can YOU be an ambassador?</Header>
                <LeadText>
                  <ol className={styles.LeadList}>
                    <li>
                      An ambassador is anyone who wants to make this world a
                      happier place. It’s easy - get together with friends, old
                      and new, and start a group.
                    </li>
                    <li>
                      Simply grab inspiration from Project Happiness social
                      media or email posts, and spark a conversation.
                    </li>
                    <li>
                      We'll share your group photos of how you are activating
                      your happiness. Just tag us and use #happinessambassadors
                      &amp; #projecthappiness.
                    </li>
                  </ol>
                </LeadText>
                <Button color="purple-dark" href={ROUTES.ambassadorSignUp()}>
                  YES, I'm In!
                </Button>
              </Stack>
            </LayoutUI>
          </ColoredSection> */}

        <div className={`${PAGE_NAME}__container`}>
          <LayoutUI narrow>
            <Stack alignment="center" spacing="extraLoose" vertical>
              <Stack alignment="center" spacing="extraLoose" vertical>
                <Header>Featured Ambassadors</Header>
                <TwoUp>
                  {featuredAmbassador
                    .reverse()
                    .map(({ name, profileImage, tagLine, shortBio, slug }) => (
                      <FeaturedAmbassador
                        image={profileImage.file.url}
                        name={name}
                        tagLine={tagLine.tagLine}
                        text={shortBio.shortBio}
                        slug={slug}
                        key={name}
                      />
                    ))}
                </TwoUp>
              </Stack>
              <Stack alignment="center" vertical>
                <Header>Ambassadors</Header>
                <div className={`${PAGE_NAME}__container--normal`}>
                  {normalAmbassador
                    .reverse()
                    .map(({ name, profileImage, tagLine, shortBio, slug }) => (
                      <NormalAmbassador
                        image={profileImage.file.url}
                        name={name}
                        tagLine={tagLine.tagLine}
                        text={shortBio.shortBio}
                        slug={slug}
                        key={name}
                      />
                    ))}
                </div>
              </Stack>
            </Stack>
          </LayoutUI>
        </div>

        {/* <ColoredSection color="purple">
            <LayoutUI>
              <Stack alignment="center">
                <Stack.Item fill>
                  <Header>Do you want to activate more happiness?</Header>
                </Stack.Item>
                <Button color="purple-dark" href={ROUTES.ambassadorSignUp()}>
                  YES, I’m in!
                </Button>
              </Stack>
            </LayoutUI>
          </ColoredSection> */}
      </div>
    </Layout>
  );
};

AmbassadorPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
};

export default AmbassadorPage;

export const AmbassadorPageQuery = graphql`
  query AmbassadorPageQuery($slug: String!) {
    contentfulPageAmbassadorHub(slug: { eq: $slug }) {
      metatags {
        metaTitle
        metaImage {
          title
          file {
            url
          }
        }
        metaDescription
      }
      slug
      hero {
        title
        images {
          file {
            url
          }
          title
          description
        }
        subtitle {
          subtitle
        }
        video {
          file {
            url
            fileName
            contentType
          }
        }
        link {
          link
          text
        }
      }
    }
    allContentfulPageIndividualAmbassador(limit: 100) {
      edges {
        node {
          name
          profileImage {
            file {
              url
              fileName
            }
          }
          tagLine {
            tagLine
          }
          shortBio {
            shortBio
          }
          slug
          featuredAmbassador
        }
      }
    }
  }
`;
