/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import {
  Stack,
  Header,
  Subheader,
  WithFloatingArrow
} from '../../../../../ui-kit';

import './featured-ambassador.scss';

export default function FeaturedAmbassador({
  slug,
  image,
  name,
  tagLine,
  text
}) {
  const COMPONENT_NAME = 'featured-ambassador';

  return (
    <Link className={`${COMPONENT_NAME}__container`} to={slug}>
      <Stack vertical>
        <div
          className={`${COMPONENT_NAME}__image`}
          style={{
            backgroundImage: `url(${image})`
          }}
        />
        <Header>
          <span className={`${COMPONENT_NAME}__name`}>{name}</span>
        </Header>
        <Subheader>{tagLine}</Subheader>
        <p>{text}</p>
        <WithFloatingArrow>
          {floatingArrow => (
            <div className={`${COMPONENT_NAME}__link`}>
              Read more
              {floatingArrow}
            </div>
          )}
        </WithFloatingArrow>
      </Stack>
    </Link>
  );
}
